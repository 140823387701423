import logo from '../../images/site_logo.png'
import React from 'react';
import './footer.css'

export default function Footer() {
    return (<div>
        <hr style={{ width: "100%", height: 2 }} />
        <div className="row">
            <div className="col-md-6">
                <a href = "https://tinychef.ai/" style={{ paddingLeft: "3%", textDecoration:"none", color:"#000" }}>
                    Powered By &nbsp; &nbsp;
                    <img src={logo} style={{ height: 18 }} alt="footer"/>
                </a>
            </div>
            <div className="col-md-6">
                <p style={{ float: "right", paddingRight: "3%", fontSize: 14 }}><a href="https://tinychef.ai/" style={{textDecoration:"none", color:"#000"}}>About Tinychef  </a> 
                 |  <a href="https://tinychef.ai/terms-conditions/" style={{textDecoration:"none", color:"#000"}}>Terms of service</a></p>
            </div>
        </div>
        <div className="grad1"></div>
    </div>)
}