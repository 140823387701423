import React, { Fragment } from 'react';
import LocationScreen1 from '../components/Location/Screen1';
import Axios from 'axios';
import MultipleLocation from '../components/Location/MultipleLocation';
import FullLocation from '../components/Location/DetailLocationScreen';
import { LinearProgress } from '@material-ui/core';



class LocationContainer extends React.Component {

  state = {
    address : [],
    addAddress : false,
    response_rec : false
  }

  addAddress = () =>{
    this.setState({addAddress:true})
    localStorage.setItem("address", null)
  }

  hideAddress = () =>{
    this.setState({addAddress:true})
  }

  componentDidMount(){
  
    document.body.style.fontFamily = "SFProDisplay"
    sessionStorage.setItem("change-vendor", false)

    var headers = { "Access-Control-Allow-Origin": "*" }

    Axios.get(process.env.REACT_APP_BACKEND_URL+'user/getuser/' + localStorage.getItem('zeluser') + '/v1', headers)
    .then(res => {
      console.log(res.data.data)
      this.setState({address: res.data.data.addresses, response_rec : true})
    }).catch(err => {
      console.error(err)
      this.setState({response_rec:true})
    })
  }

    render() {
      if(!this.state.response_rec){
        return <LinearProgress/>
      }
      return (<Fragment>
        {this.state.addAddress?<FullLocation/> : 
        (this.state.address && this.state.address.length>0)? <MultipleLocation address = {this.state.address} addAddress = {this.addAddress}/>:<LocationScreen1/>}
        {/* {this.state.addAddress?<LocationScreen1/> :  <MultipleLocation address = {this.state.address} addAddress = {this.addAddress}/>} */}
      </Fragment>);
    }
  }

export default LocationContainer;