import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC1LwVy7NSTPfEPdVVvBkXGwF2PWp7odJI",
    authDomain: "zelish-92cd5.firebaseapp.com",
    databaseURL: "https://zelish-92cd5.firebaseio.com",
    projectId: "zelish-92cd5",
    storageBucket: "zelish-92cd5.appspot.com",
    messagingSenderId: "649096073549",
    appId: "1:649096073549:web:fb20d2849c4d4c76666704",
    measurementId: "G-L4SCW9VLQJ"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();