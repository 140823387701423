import React, { Fragment } from 'react';
import ShowVendor from '../components/Vendor/Vendor';
import axios from 'axios';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Redirect, useHistory } from "react-router-dom";
import { LinearProgress } from '@material-ui/core';
import NoVendor from '../components/Vendor/NoVendor';

// var history = useHistory();

class VendorContainer extends React.Component {

  componentDidMount() {
    document.body.style.fontFamily = "SFProDisplay"
    this.setState({ loading: true })
    this.loadData()
  }

  constuctor() {
    this.changeAddres = this.changeAddres.bind(this);
  }

  state = {
    vendors: [],
    showSlots: false,
    error: false,
    loading: false
  }

  loadData = () => {

    var jsonObject = {};

    if (localStorage.getItem("guest") === "true") {
      var items = JSON.parse(localStorage.getItem("cart")).shopNowResponses
        items.forEach((item) =>{
            item.name = item.itemName
            item.quantityValue = item.quantity
         })
      jsonObject = {
        userId : "GUEST",
        address : localStorage.getItem("address"),
        city : localStorage.getItem("city"),
        latitude : localStorage.getItem("lat"),
        longitude : localStorage.getItem("long"),
        cartItems : items
      }
    } else {
      jsonObject = {
        userId: localStorage.getItem('zeluser')
      }
    }

    var myJsonString = JSON.stringify(jsonObject);
    this.getVendorList(myJsonString)
  }

  getVendorList = (myJsonString) => {
    axios.post(process.env.REACT_APP_BACKEND_URL + 'vendor/getVendors', myJsonString, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
      }
    })
      .then(res => {
        console.log(res.data.data)
        this.setState({ vendors: res.data.data.resultList, loading: false })
        if (this.state.vendors === null || this.state.vendors.length == 0) {
          this.setState({ error: true })
        }
        this.setState({ loading: false })
      }).catch(err => {
        console.log(err)
        this.setState({ error: true, loading: false })
      })
  }

  changeAddres = () => {
    let history = useHistory();
    history.push("/location");
  }

  showSlots = () => {
    this.setState({ showSlots: true })
  }

  hideSlots = () => {
    this.setState({ showSlots: false })
  }


  render() {

    const alert = <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
       Can't Find Vendor — <strong onClick={this.changeAddres}>Try Changing Address</strong>
    </Alert>;
    if (this.state.error) {
      return <NoVendor/>;
    }

    if(this.state.vendors.length>0 && sessionStorage.getItem("change-vendor")!=="true"){
      {localStorage.setItem("vendor", JSON.stringify(this.state.vendors[0]))
      localStorage.setItem("vendorVid", this.state.vendors[0].id)
    }
     return  <Redirect to="/cart"/>
    }

    return (<Fragment>
      {this.state.loading && <LinearProgress />}
      {this.state.vendors && (this.state.vendors.length > 0 && <ShowVendor vendors={this.state.vendors} showSlots={this.showSlots} />)}
    </Fragment>);
  }
}

export default VendorContainer;