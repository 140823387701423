import React from 'react';
import Footer from '../Footer/footer';
import './Vendor.css'
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import group from '../../images/group.png'
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import amplitude from 'amplitude-js'

export default function ShowVendor(props) {

    let history = useHistory();

    const updateLocation = () => {
        history.push('/location')
    }

    const selectVendor = (v) => {

        localStorage.setItem("vendor-cart", JSON.stringify(v.cartItemsList))
        localStorage.setItem("vendor-total", v.totalPrice)

        amplitude.logEvent('Vendor_Proceed', {
            "Recipe_Url": localStorage.getItem("recUrl"),
            "Recipe_Name": localStorage.getItem("recName"),
            "Domain": localStorage.getItem("domain"), 
            "Vendor_Name": v.vendorName
        })

        localStorage.setItem("vendor", JSON.stringify(v))
        history.push('/cart')
        localStorage.setItem("vendorVid", v.id)
    }

    

    return (<div className="container">
         <div className="col-md-10 offset-md-1 col-sm-12 arrow">
            <span style={{
                height: 40,
                width: 40,
                padding: 6,
                backgroundColor: "#1d1d1d", color: "#fff",
                borderRadius: "50%",
                display: "inline-block"
            }}
                onClick={() => { history.push("/location") }}><ArrowBackIcon /></span>
        </div>
        <div className="col-md-10 offset-md-1">
            <Paper elevation={3}>
                <div className="outer col-md-10 offset-md-1">
                    <h1 className="title-vendor" style={{ paddingTop: "10%" }}>Choose Vendor</h1>
                    <br />
                    <div className="row">
                        <div className="col-1 col-sm-1"> 
                        <RoomIcon /> 
                        </div>
                        <div className="col-10 col-md-10 addr12">
                            <span className="address" > {localStorage.getItem("address")}</span>
                        </div>

                        <div className="col-md-1">
                            <a className="change menu_links" style={{color:"#ff7907", textDecoration:"none"}} onClick={updateLocation}>Change</a>
                        </div>
                    </div>
                    <br />

                    <div className="row">
                        {props.vendors.length > 0 ?
                            props.vendors.map((vendor, index) => {
                                vendor.index = index + 1;
                                return <Grid item style={{ backgroundColor: "#fff" }} sm={12} md={6} key={index}>
                                    <Paper style={{
                                        backgroundImage: 'url(' + group + ')' }} className="vendor-card">
                                        <Typography style={{ paddingTop: "10%", paddingLeft: "5%", fontWeight:600 }}>{vendor.vendorName}</Typography>

                                        <Typography style={{ paddingTop: "3%", paddingLeft: "5%" }}>
                                            <div className="row">
                                                <div className="col-8">
                                                    <span style={{fontSize:14, fontWeight:600}}>{vendor.availability} items available out of {vendor.totalItems} </span>
                                                    <span>&nbsp; |</span>
                                                </div>
                                                <div className="col-3 price" > ₹ {vendor.totalPrice}<br /></div>
                                            </div>
                                            <span style={{fontSize:14, fontWeight:300}}> Groceries delivered in {vendor.deliveryTime}</span>
                                        </Typography>

                                        <button
                                        className="btn"
                                            style={{
                                                borderRadius: 5.4,
                                                paddingLeft: 15, paddingRight: 15,
                                                paddingTop: 4, paddingBottom: 5,
                                                color: "#fff", backgroundColor: "#000",
                                                marginTop: 29, marginLeft: "5%"
                                            }}
                                            onClick={() => { selectVendor(vendor) }}>
                                            Show Ingredients
                         </button>
                                    </Paper>
                                </Grid>
                            }) :
                            <h4 style={{ textAlign: "center", marginTop: "10px", marginLeft: "5%" }}>No Vendors Yet! Start Looking</h4>}
                    </div>

                    <br />
                    <br />

                </div>
                <Footer />
            </Paper>
        </div>
    </div>)
}