import React from 'react';
import { Paper,  TextField, InputAdornment, Typography } from '@material-ui/core';
import flag from '../../images/flag.png'
import cross from '../../images/shape.png'
import phoneImg from '../../images/phone.png'
import "firebase/auth";
import firebase from "firebase";
import "firebase/firestore";
import amplitude from "amplitude-js";
import Footer from '../Footer/footer';
import "./firebase";
import './Login.css'
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function Login(props) {
    let history = useHistory();
    const [phone, setPhone] = React.useState('');
    const [error, setError] = React.useState('');
    const [btn, setBtn] = React.useState(true);

    const clearMobile = () => {
        setPhone("")
    }

    const handlePhoneChange = (e) =>{
        
        let ph = e.target.value
        let pattern = /^[0-9]{10}/
    
        if (ph === "") {
            setError("Field Required")
            setPhone(ph);
        } else if (!pattern.test(ph)) {
            setError("Please Enter a Valid Phone Number")
            setPhone(ph);
        } else if(ph.length===10){
            setError("")
            setBtn(false)
            setPhone(ph);
            localStorage.setItem("phone", ph)
        }
        
    }


    var handleSignUp = (event) => {
        props.startLoading()
        amplitude.logEvent('Phone_Number_Success', {
            "Recipe_Url": localStorage.getItem("recUrl"),
            "Recipe_Name": localStorage.getItem("recName"),
            "Domain": localStorage.getItem("domain"), 
            "Phone": phone
        })
        setBtn(true)
        console.log(event.target)
        event.preventDefault();
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('loginbtn', {
          'size': 'invisible',
          'callback': function (response) {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            console.log("Response is " + response)
          }
        });
    
        const appVerifier = window.recaptchaVerifier;
        firebase.auth().signInWithPhoneNumber("+91" + phone, appVerifier)
          .then(function (confirmationResult) {
            console.log("Success");
            window.confirmationResult = confirmationResult;
            props.showOtp()
            props.stopLoading()
          })
          .catch(function (error) {
            console.log("Error:" + error.code);
          });
      };

    return (<div className="container">
         <div className="col-md-8 offset-md-2 col-sm-12 arrow">
            <span style={{
                height: 40,
                width: 40,
                padding: 6,
                backgroundColor: "#1d1d1d", color: "#fff",
                borderRadius: "50%",
                display: "inline-block"
            }}
                onClick={() => { history.push("/") }}><ArrowBackIcon /></span>
        </div>
        <div className="col-md-8 offset-md-2">
            <Paper elevation={3}>
                <div className="outer">
                    <div className="row">
                        <div className="col-md-8" style={{ paddingTop: "25%", marginLeft: "5%", paddingBottom: "20%" }}>
                            <h1 style={{fontWeight:600, fontFamily:"SFProDisplay-Semibold"}}>Get Started!</h1>
                            <h5 style={{ marginTop: "4%", fontFamily:"SFProDisplay-Semibold", fontWeight:600 }}>Enter Your Phone Number </h5>
                            <p>We need your contact number to enable smooth delivery</p>
                            <TextField
                                style={{ backgroundColor: "#F0F0F0", padding: 10 }}
                                className="text-phone"
                                value={phone}
                                inputProps = {{inputMode: 'numeric'}}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={flag} alt="india" />&nbsp;+91 &nbsp;<span style={{ color: "#d0d0d0", fontSize: 30 }}>|</span> &nbsp;
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <img src={cross} style={{ paddingRight: 10 }} alt="delete" onClick={clearMobile} />
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true,
                                }}
                                onChange={handlePhoneChange} />

                            <Typography className="text-danger" style={{paddingTop:"1%"}}>{error}</Typography><br />
                            {/* {captcha && <div id="recaptcha-container"></div>} 

                             <Typography >{otpSent}</Typography><br /> */}
                        </div>
                        <div className="col-md-3" >
                            <img src={phoneImg} className="img-mobile" alt="mobile"/>
                            <button type="button" disabled={btn} id="loginbtn" className="loginbtn btn btn-success btn-rounded btn-continue" 
                            style=
                            {{ borderRadius: 30, padding: 10, width: 150  }}
                            onClick={handleSignUp}>
                                Continue
                </button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Paper>
        </div>
    </div>)
}