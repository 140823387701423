import React from 'react';

export default function UnavailableMember({ item, index}) {

    return (
        <div className="row" >
          <div className="col-6" style={{ paddingLeft: "3.5%", marginBottom:5 }}>
            <span className="item-name"><s>{(index + 1) + ".   " + item.name}</s></span><br />
            <span>&nbsp; &nbsp;</span>
            <span className="gms" style={{ color: "#696969", float: "left", fontSize: 13, paddingLeft: "3.5%" }}>
              {"  " + item.quantityValue + " " + item.quantityUnit}
            </span><br/>
          </div>
          <hr style={{ width: "90%", height: 2, marginTop: "0"}} />
        </div>
      );
    }

