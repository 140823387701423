import React from 'react';
import './CartScreen.css'
import basil from '../../images/placeholder.jpg'
import breakfast from '../../images/breakfast.png'
import ico_minus from '../../images/ico-minus.png'
import ico_plus from '../../images/ico-plus.png'
import Axios from 'axios';

export default function CartHeader(props) {
    const [value, setValue] = React.useState(parseInt(localStorage.getItem("servings")))


    const increaseServings = () => {
        var ser = value + 1
        setValue(ser)
        props.servingsUpdate(ser)
    }

    const decreaseServings = () => {
        var ser = value - 1
        setValue(ser)
        props.servingsUpdate(ser)
    }




    return (
        <div className="row" style={{ paddingTop: "3%", paddingBottom: "3%", paddingLeft: "3%" }}>
            <div className="col-md-2">
                <img src={(localStorage.getItem("imgUrl") !== 'null' && localStorage.getItem("imgUrl") !== 'undefined') ? localStorage.getItem("imgUrl") : basil} style={{ height: 80, borderRadius: 15 }} alt="rec" />
            </div>
            <div className="col-md-5 recName" >
                    {localStorage.getItem("recName")}
            </div>
            <div className="col-md-5">
                {/* <div className="cart-ser1" style={{ paddingRight: "8%", paddingTop: "10%" }}>
                    <img src={ico_plus} className="ico_minus" alt="" style={{ float: "right", paddingRight: "1%" }} onClick={increaseServings} />
                    <span style={{ float: "right", paddingRight: "2%", paddingLeft: "2%" }} >{value}</span>
                    <img src={ico_minus} className="ico_minus" alt="" onClick={decreaseServings} style={{ float: "right", paddingRight: "1%" }} />
                    <span style={{ fontSize: "1em", fontWeight: "bold", float: "right", paddingRight: "3%" }}>
                        Servings</span>
                    <img src={breakfast} alt="" style={{ float: "right", paddingRight: "1%" }} />
                </div>

                <div className="cart-ser2" style={{paddingTop:"5%" }}>
                    <img src={breakfast} alt="" style={{ paddingRight: "1%", }} />
                    <span style={{ fontSize: "1em", fontWeight: "bold", paddingRight: "3%" }}>Servings</span>
                    <img src={ico_minus} className="ico_minus" alt="" onClick={decreaseServings} style={{ paddingRight: "1%" }} />
                    <span style={{  paddingRight: "2%", paddingLeft: "2%" }} >{value}</span>
                    <img src={ico_plus} className="ico_minus" alt="" style={{  paddingRight: "1%" }} onClick={increaseServings} />
                </div> */}
            </div>
            <br />
        </div>
    )
}
