import React from 'react';
import './Location.css'
import Footer from '../Footer/footer';
import { Paper } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import Axios from 'axios';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function MultipleLocation(props) {

    let history = useHistory();

    const addAddress = () => {
        props.addAddress()
    }

    const openVendor = () => {
     //   console.log(localStorage.getItem("newuser"))
        history.push('/vendor')
    }

    const selectAddress = (t, i) => {

        var myJsonString = JSON.stringify({
            type: t,
            userId: localStorage.getItem("zeluser")
        })

        localStorage.setItem("address",props.address[i].houseNo==="House No"? props.address[i].address:props.address[i].houseNo +", "+props.address[i].buildingName+", "+props.address[i].city+", "+props.address[i].address )


        Axios.post(process.env.REACT_APP_BACKEND_URL + 'v1/users/' + localStorage.getItem("zeluser") + '/address', myJsonString, {
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'api-key': '5a438496bc016495d52823'
            }
        }
        ).then(res => {
           openVendor()
         console.log(res.data)
        }).catch(err => {
            console.log(err)
            alert("error in select address")
        })
    }

    return (<div className="container">
         <div className="col-md-8 offset-md-2 col-sm-12 arrow">
            <span style={{
                height: 40,
                width: 40,
                padding: 6,
                backgroundColor: "#1d1d1d", color: "#fff",
                borderRadius: "50%",
                display: "inline-block"
            }}
                onClick={() => { history.push("/") }}><ArrowBackIcon /></span>
        </div>
        <div className="col-md-8 offset-md-2">
            <Paper elevation={3}>
                <div className="col-md-10 offset-md-1">
                    <h1 className="title">Delivery Location</h1>
                    <p className="text"><b>Multiple addresses in this location</b></p>
                    <div className="row" style={{height:350, overflow:"scroll"}}>
                        {props.address.map((item, index) => {
                            return <div className="col-md-6" key={index} style={{marginTop:30}}>
                                <Paper elevation={3}>
                                    <div className="container">
                                        <br />
                                        <p><b>{item.type}</b></p>
                                        <p style={{ height: 90, fontSize: 14 }}>{item.houseNo==="House No"? item.address + "\n" +
                                            item.checkoutPhone:item.houseNo + ", " + item.buildingName + ", " + item.address + "\n" +
                                            item.checkoutPhone}
                                        </p>
                                       
                                        <div align="center">
                                            <button onClick={() => { selectAddress(item.type, index) }} align="center" type="button" className="btn btn-success btn-rounded"
                                                style={{
                                                    borderRadius: 30,
                                                    padding: 8,
                                                    width: 130
                                                }}>
                                                Deliver Here</button>
                                        </div>
                                        <br />
                                    </div>
                                </Paper>
                            </div>
                        })}

                        <div className="col-md-6">
                            <Paper />
                        </div>
                    </div>
                    <br />
                    <p className="add menu_links" style={{ color: "#ff7907", textDecoration: "none"}} onClick={addAddress}>+Add New Delivery Address</p>
                </div>
                <Footer />
            </Paper>
        </div>
    </div>)
}