import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useHistory } from "react-router-dom";
import Footer from '../Footer/footer';
import orderCancel from '../../images/order-cancel.png'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


export default function NoVendor() {

    const goHome = () => {
        history.push('/')
    }

    let history = useHistory();
    return (<div className="container">
        <div className="col-md-8 offset-md-2 col-sm-12 arrow">
            <span style={{
                height: 40,
                width: 40,
                padding: 6,
                backgroundColor: "#1d1d1d", color: "#fff",
                borderRadius: "50%",
                display: "inline-block"
            }}
                onClick={() => { history.push("/location") }}><ArrowBackIcon /></span>
        </div>
        <div className="col-md-8 offset-md-2">
            <Paper elevation={3}>
                <div className="">
                    <div class="row" style={{ paddingTop: 50, paddingLeft: "10%" }}>
                        <span style={{ float: "left" }}>
                            <img class="manImg" src={orderCancel} style={{ maxWidth: 300 }}></img>
                        </span>
                    </div>
                    <div class="row">
                        <div class="col-md-11">
                            <h1 style={{ letterSpacing: 2, textStyle: "bold", fontWeight: "bolder", marginLeft: "5%", marginTop: 30 }}>
                                No Vendor Available</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-11" style={{ marginLeft: "5%" }}>
                            <p style={{ letterSpacing: 1, marginTop: 10 }}>
                            Delivery not supported in your area yet. We currently deliver only in Bangalore, Mumbai, Delhi, Pune &amp; Kolkata. Please check back later or contact our support at
				</p>
                            <a href="mailto:talkto@tinychef.ai" className="phone-contact" style={{ textDecoration: "none", color: "#ff7907" }}>talkto@tinychef.ai</a>
                        </div>
                    </div>
                    <br/>  <br/>
                </div>
                <Footer />
            </Paper>
        </div>
    </div>)
}
