import { InputAdornment, TextField } from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import './Search1.css'
import SearchIcon from '@material-ui/icons/Search';

let autoComplete;

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current
    );
    autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery)
    );
}

const getCity = (addressArray) => {
    let city = '';
    for (let i = 0; i < addressArray.length; i++) {
        if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
            city = addressArray[i].long_name;
            return city;
        }
    }
};

async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    const query = addressObject.formatted_address;
    updateQuery(query);
    localStorage.setItem("address", query)
    localStorage.setItem("lat", addressObject.geometry.location.lat())
    localStorage.setItem("long", addressObject.geometry.location.lng())
    localStorage.setItem("city", getCity(addressObject.address_components))

    console.log(addressObject);
}

function SearchLocationInput() {
    const [query, setQuery] = useState("");
    const autoCompleteRef = useRef(null);

    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=AIzaSyC1LwVy7NSTPfEPdVVvBkXGwF2PWp7odJI&libraries=places`,
            () => handleScriptLoad(setQuery, autoCompleteRef)
        );
    }, []);

    return (
        <div className="input-group">
                <span  style={{ backgroundColor: "#f0f0f0", padding: "5px" }} class="input-group-addon"><SearchIcon/></span>
            <input
                className="form-control in-loca"
                ref={autoCompleteRef}
                onChange={event => setQuery(event.target.value)}
                placeholder="Search for area, Street name.."
                style={{ backgroundColor: "#f0f0f0", padding: "5px" }}
                value={query}
            />

        </div>

    );
}

export default SearchLocationInput;