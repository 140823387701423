import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import Footer from '../Footer/footer';
import OtpInput from 'react-otp-input';
import otpimg from '../../images/otp.png'
import { useHistory } from "react-router-dom";
import Axios from 'axios';
import amplitude from "amplitude-js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function Otp(props) {

    let history = useHistory();

    const [otp, setOtp] = React.useState('');
    const [btn, setBtn] = React.useState(false);
    const [otpText, setOtpText] = React.useState('');


    var onVerifyCodeSubmit = () => {
        props.startLoading()
        setBtn(true)
        
        const verificationId = otp;
        window.confirmationResult
            .confirm(verificationId)
            .then(function (result) {
                getUser()
            }).catch(function (error) {
                props.stopLoading()
                setBtn(false)

                // User couldn't sign in (bad verification code?)
                setOtp('')
                if(error.code=="auth/invalid-verification-code"){
                    setOtpText("Wrong OTP entered. Resend OTP and try again please")
                }else{
                    setOtpText(error.code)
                }
                console.error("Error while checking the verification code", error);
            });
    };

    const handleOtpChange = (otpp) => {
        setOtp(otpp)
        
    }

    const changePhone = () => {
        props.showMobile()
    }

    const getUser = () => {
        amplitude.logEvent('OTP_Success', {
            "Recipe_Url": localStorage.getItem("recUrl"),
            "Recipe_Name": localStorage.getItem("recName"),
            "Domain": localStorage.getItem("domain"), 
        })
        var myJsonString = JSON.stringify({
            phoneNumber: localStorage.getItem("phone"),
            phoneVerified: "YES",
            userAuth: "PHONE",
            source : "ShopNow"
        });

        Axios.post(process.env.REACT_APP_BACKEND_URL+'user/adduser/v1', myJsonString, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        }).then(res => {
                localStorage.setItem("zeluser", res.data.data.userId)
                addCart()
            }).catch(err=>{
                alert("Couldn't fetch user")
            })
    }

    const addCart = () => {
        var items = JSON.parse(localStorage.getItem("cart")).shopNowResponses
        console.log(items)
        items.forEach((item) =>{
            item.name = item.itemName
            item.quantityValue = item.quantity
         })
        var jsonString = JSON.stringify(items);
        console.log(jsonString)

        items.length>0 && 
           Axios.post(process.env.REACT_APP_BACKEND_URL+'shopnow/cart/add?userId='+localStorage.getItem("zeluser"), jsonString, {
              headers: {
                  'Content-Type': 'application/json',
                  "Access-Control-Allow-Origin": "*"
              }}).then(res => {
                  console.log(res.data);
                  props.stopLoading()
                  history.push('/location')
              }).catch(error=>{
                  alert("Couldn't add cart")
                console.log(error);
              })
    }

    return (<div className="container">
         <div className="col-md-8 offset-md-2 col-sm-12 arrow">
            <span style={{
                height: 40,
                width: 40,
                padding: 6,
                backgroundColor: "#1d1d1d", color: "#fff",
                borderRadius: "50%",
                display: "inline-block"
            }}
                onClick={changePhone}><ArrowBackIcon /></span>
        </div>
        <div className="col-md-8 offset-md-2">
            <Paper elevation={3}>
                <div className="outer">
                    <div className="row">
                        <div className="col-md-8" style={{ paddingTop: "25%", marginLeft: "5%", paddingBottom: "20%" }}>
                            <h1 style={{fontWeight:600, fontFamily:"SFProDisplay-Semibold"}}>Almost done!</h1>
                            <h5 style={{ marginTop: "4%", fontFamily:"SFProDisplay-Semibold", fontWeight:600  }}>Enter Verification Code</h5>
                            <p>We have just sent a verification code to +91 {localStorage.getItem("phone")}</p>
                            <OtpInput
                                value={otp}
                                onChange={handleOtpChange}
                                numInputs={6}
                                separator={<span>&nbsp;&nbsp;</span>}
                                inputStyle={{
                                    width: '2.25rem',
                                    height: '2.25rem',
                                    fontSize: '1rem',
                                    borderRadius: 4,
                                }}
                                isInputNum={true}
                            />
                            <Typography className="text-danger">{otpText}</Typography>
                            <p className="change-phone menu_links" style={{ color: "#ff7907", textDecoration: "none"}} onClick={changePhone}>Change the phone number</p></div>
                        <div className="col-md-3" >
                            <img src={otpimg} className="img-mobile" alt="mobile" />
                            <button type="button"
                            disabled={btn}
                             className="btn btn-success btn-rounded btn-continue"
                                style={{
                                    borderRadius: 30,
                                    padding: 10,
                                    width: 130,
                                }}
                                onClick={onVerifyCodeSubmit}>
                                Verify
    </button>
                        </div>

                    </div>
                </div>
                <Footer />
            </Paper>
        </div>
    </div>)
}