import React, { Fragment } from 'react';
import SlotSelect from '../components/Vendor/SlotSelect';
import Axios from 'axios';

class SlotContainer extends React.Component {

    state={
        vendor : null
    }
    
  

    componentDidMount(){
        document.body.style.fontFamily = "SFProDisplay"

        var myJsonString = JSON.stringify({
            userId: localStorage.getItem('zeluser'),
            vendorId: localStorage.getItem('vendorVid')
        });
        Axios.post(process.env.REACT_APP_BACKEND_URL+'checkoutList', myJsonString, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        }).then(res => {
            this.getUser()
            console.log(localStorage.getItem("vendor"))
            localStorage.setItem("vendor", JSON.stringify(res.data.data))
            this.setState({vendor: res.data.data})
            console.log(res.data.data)
        })
    }

    
    
       getUser = () => {
        var headers = { "Access-Control-Allow-Origin": "*" }

        Axios.get(process.env.REACT_APP_BACKEND_URL+'user/getuser/' + localStorage.getItem('zeluser') + '/v1', headers)
          .then(res => {
            //this.setState({ phone: res.data.data.phoneNumber })
            localStorage.setItem("phone", res.data.data.phoneNumber)
          }).catch(err => {
            console.error(err)
            alert("An Error Occured in Get User")
          })
    
      }

    render() {
        return (<Fragment>
            {this.state.vendor && <SlotSelect vendor={this.state.vendor}/> }
            
        </Fragment>);
    }
}

export default SlotContainer;