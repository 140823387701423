import { Paper } from '@material-ui/core';
import React from 'react';
import './Checkout.css'
import Footer from '../Footer/footer';
import upi from '../../images/upi.png'
import arrow from '../../images/arrow.png'
import credit from '../../images/credit.png'
import cod from '../../images/cod.png'
import netbanking from '../../images/netbanking.png'
import axios from 'axios';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from "react-router-dom";


export default function ChoosePayment(props) {

  let history = useHistory();

  const startLoading = () => {
    props.startLoading()
  }

  const stopLoading = () => {
    props.stopLoading()
  }

  const placeOrder = (pmtMode) => {

    startLoading()
    var jsonPlace = {
      date: localStorage.getItem("date"),
      paymentMode: pmtMode,
      slotIndex: localStorage.getItem("slotIndex"),
      userId: localStorage.getItem("zeluser"),
      vendorId: localStorage.getItem("vendorVid"),
      recipeURL: localStorage.getItem("recUrl")
    }

    var myJsonString = JSON.stringify(jsonPlace);
    console.log(myJsonString);
    axios.post(process.env.REACT_APP_BACKEND_URL + 'placeOrder', myJsonString, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
      }
    }).then(res => {
      stopLoading()

      if (pmtMode === "COD") {
        window.location.href = process.env.REACT_APP_URL + "thankyou";
      }else{
        setDataValue(res.data.data.cfResponse.cfAppId, res.data.data.zelishOrderId, res.data.data.orderId, res.data.data.grandTotal, res.data.data.cfResponse.cftoken)
      }
    }).catch(err => {
      console.log(err);
      stopLoading()
      alert("An Error Occured in Place Order")
    })
  }

  const setDataValue = (appId, orderId, objectId, orderAmount, token) => {
    var data = {
      appId: appId,
      orderId: orderId,
      orderAmount: parseInt(orderAmount),
      customerName: "Tinychef Order",
      customerEmail: "talkto@tinychef.ai",
      customerPhone: localStorage.getItem("phone"),
      notifyUrl: process.env.REACT_APP_BACKEND_URL + "order/verifySignature/",
      returnUrl: process.env.REACT_APP_BACKEND_URL + "shopnow/checkResponse",
      orderCurrency: "INR",
      paymentToken: token
    }

    var headers = { "Access-Control-Allow-Origin": "*" }

    axios.get(process.env.REACT_APP_BACKEND_URL + 'order/signature/' + objectId, headers).then(res => {
      data.signature = res.data.data;
      console.log(data)
      localStorage.setItem("data", JSON.stringify(data))
      stopLoading()
      props.callValue()
    }).catch(err => {
      console.error(err)
      alert("An Error Occured in Hash Generation")
      stopLoading()
    })
  }


  return (<div className="container">
    <div className="col-md-8 offset-md-2 col-sm-12 arrow">
      <span style={{
        height: 40,
        width: 40,
        padding: 6,
        backgroundColor: "#1d1d1d", color: "#fff",
        borderRadius: "50%",
        display: "inline-block"
      }}
        onClick={() => { history.push("/slot") }}><ArrowBackIcon /></span>
    </div>
    <div className="col-md-8 offset-md-2">
      <Paper elevation={3} >
        <div>
          <div className="col-md-10 offset-md-1" style={{ paddingBottom: "10%" }}>
            <div className="title">
              <h1 style={{ letterSpacing: 1, textStyle: "bold", fontWeight: "bolder" }}>Payment method</h1>
              <br />
            </div>

            {(props.pModes.includes("CASHFREE") || props.pModes.includes("PAYU")) && <div>
              <div className="col-md-10 ingredient-style menu_links" onClick={() => { placeOrder("CASHFREE") }}>
                <span style={{ float: "left" }}><img class="manImg" src={upi} alt="" /></span> &nbsp; &nbsp;  &nbsp;
                  <span style={{ float: "center" }}>Pay via UPI</span>
                <a style={{ float: "right" }}> <img class="manImg" src={arrow} alt="" onClick={() => { placeOrder("CASHFREE") }} />  </a>
                <hr />
              </div>

              <div className="col-md-10 ingredient-style menu_links" onClick={() => { placeOrder("CASHFREE") }}>
                <span style={{ float: "left" }}><img class="manImg" src={credit} alt="" /></span> &nbsp; &nbsp;  &nbsp;
                  <span style={{ float: "center" }}>Credit/Debit cards</span>
                <span style={{ float: "right" }}> <img class="manImg" src={arrow} alt="" onClick={() => { placeOrder("CASHFREE") }} />  </span>
                <hr />
              </div>

              <div className="col-md-10 ingredient-style menu_links" onClick={() => { placeOrder("CASHFREE") }}>
                <span style={{ float: "left" }}><img class="manImg" src={netbanking} alt="" /></span> &nbsp; &nbsp;  &nbsp;
                  <span style={{ float: "center" }}>Netbanking</span>
                <span style={{ float: "right" }}> <img class="manImg" src={arrow} alt="" onClick={() => { placeOrder("CASHFREE") }} />  </span>
                <hr />
              </div></div>}

            {props.pModes.includes("COD") &&
              <div className="col-md-10 ingredient-style menu_links" onClick={() => { placeOrder("COD") }}>
                <span style={{ float: "left" }}><img class="manImg" src={cod} alt="" /></span> &nbsp; &nbsp;  &nbsp;
                  <span style={{ float: "center" }}>Cash On Delivery</span>
                <a style={{ float: "right" }}> <img class="manImg" src={arrow} alt="" onClick={() => { placeOrder("COD") }} />  </a>
                <hr />
              </div>}

          </div>
        </div>
        <Footer />
      </Paper>
    </div>
  </div>)
}
