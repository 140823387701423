import React, { Fragment } from 'react';
import ChoosePayment from '../components/Checkout/ChoosePayment';
import axios from 'axios';
import { LinearProgress } from '@material-ui/core';

var data = {}

class PaymentContainer extends React.Component {


  state = {
    redirect : false,
    paymentModes : [],
    loading : false
  }

  startLoading = () => {
    this.setState({loading : true})
  }

  stopLoading = () => {
    this.setState({loading : false})  
  }

  constructor(props) {
    super(props);
    this.callValue = this.callValue.bind(this);
  }

  
  callValue = () => {
    this.setState({redirect:true})
    document.getElementById("redirectForm").submit();
  } 
  
  componentDidMount(){
    this.setState({paymentModes:JSON.parse(localStorage.getItem("vendor")).availableModes })
    
    document.body.style.fontFamily = "SFProDisplay"
    }

  render() {
    if (this.state.redirect) {
      return (
        <form id="redirectForm" method="post" action={process.env.REACT_APP_PAYMENT_URL}>
          <input type="hidden" name="appId" value={JSON.parse(localStorage.getItem("data")).appId} />
          <input type="hidden" name="orderId" value={JSON.parse(localStorage.getItem("data")).orderId} />
          <input type="hidden" name="orderAmount" value={JSON.parse(localStorage.getItem("data")).orderAmount} />
          <input type="hidden" name="orderCurrency" value="INR" />
          <input type="hidden" name="orderNote" value="" />
          <input type="hidden" name="customerName" value={JSON.parse(localStorage.getItem("data")).customerName} />
          <input type="hidden" name="customerEmail" value={JSON.parse(localStorage.getItem("data")).customerEmail} />
          <input type="hidden" name="customerPhone" value={JSON.parse(localStorage.getItem("data")).customerPhone} />
          <input type="hidden" name="returnUrl" value={JSON.parse(localStorage.getItem("data")).returnUrl} />
          <input type="hidden" name="notifyUrl" value={JSON.parse(localStorage.getItem("data")).notifyUrl} />
          <input type="hidden" name="signature" value={JSON.parse(localStorage.getItem("data")).signature} />

        </form>)
    }
    return (<Fragment>
      {this.state.loading && <LinearProgress/>}

      <ChoosePayment callValue={this.callValue} stopLoading={this.stopLoading} startLoading={this.startLoading} pModes={this.state.paymentModes} />
    </Fragment>);
  }
}

export default PaymentContainer;