import { Dialog, DialogTitle, List, ListItem, Paper, TextField, InputAdornment } from '@material-ui/core';
import React, { useEffect } from 'react';
import './CartScreen.css'
import { useHistory } from "react-router-dom";
import Footer from '../Footer/footer';
import CartHeader from './CartHeader';
import couponimg from '../../images/coupon.png'
import grocery from '../../images/grocery.png'
import grocerye from '../../images/grocerye.png'
import PhoneIcon from '@material-ui/icons/Phone';
import CartMember from './CartMember';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Axios from 'axios';
import cross from '../../images/shape.png'
import amplitude from 'amplitude-js'
import UnavailableMember from './UnavailableCart';

export default function CartScreen(props) {

    let history = useHistory();

    const [open, setOpen] = React.useState(false);
    const [apcoupon, setApcoupon] = React.useState(false);
    const [wrongCoupon, setWrongCoupon] = React.useState(false);
    const [wrongCouponMess, setWrongCouponMess] = React.useState('');

    const [coupon, setCoupon] = React.useState('');

    const makePayment = () => {
        amplitude.logEvent('Cart_Checkout', {
            "Recipe_Url": localStorage.getItem("recUrl"),
            "Recipe_Name": localStorage.getItem("recName"),
            "Domain": localStorage.getItem("domain"),
            "Amount": props.total
        })
        if (localStorage.getItem("newuser") === "true") {
            history.push('/register')
        } else {
            history.push('/slot')
        }
    }

    const searchIng = () => {
        amplitude.logEvent('Cart_Add_Ingredients', {
            "Recipe_Url": localStorage.getItem("recUrl"),
            "Recipe_Name": localStorage.getItem("recName"),
            "Domain": localStorage.getItem("domain"),
        })
        props.searchTrue()
    }

    const servingsUpdate = (ser) => {
        amplitude.logEvent('Cart_Servings_Change', {
            "Recipe_Url": localStorage.getItem("recUrl"),
            "Recipe_Name": localStorage.getItem("recName"),
            "Domain": localStorage.getItem("domain"),
        })
        props.servingsUpdate(ser)
    }

    const getCart = () => {
        props.getUserCart()
        setTimeout(updateCoupon, 1000);
    }

    const updateCoupon = () => {
        //console.log(props.coupon)
        if (localStorage.getItem("cpn") === coupon) {
            console.log("hi")
            setCoupon(localStorage.getItem("cpn"))
        } else if (localStorage.getItem("cpn") === "" && apcoupon) {
            console.log("hi2")
            setApcoupon(false)
            setWrongCoupon(true)
            setWrongCouponMess("Coupon not Applicable")
        } else {
            console.log("hi3")
        }
    }

    const showCoupons = () => {
        setOpen(true)
    }

    const hideCoupons = () => {
        setOpen(false)
    }

    const applyCoupon = () => {
        let url = process.env.REACT_APP_BACKEND_URL + 'coupon/apply?userId=' + localStorage.getItem("zeluser") + '&vendorId=' + localStorage.getItem("vendor_id") + '&couponCode=' + coupon
        let headers = { "Access-Control-Allow-Origin": "*" }
        Axios.get(url, headers)
            .then(res => {
                console.log(res)
                if (res.data.data.message == "COUPON_APPLIED") {
                    setApcoupon(true)
                    setWrongCoupon(false)
                } else {
                    setWrongCoupon(true)
                    setApcoupon(false)
                    setWrongCouponMess(res.data.data.message)
                }

                hideCoupons()
                props.getUserCart()
            }).catch(err => {
                console.log(err)
                setWrongCoupon(true)
                setApcoupon(false)
            })
    }

    const setCouponValue = (couponCode) => {
        setCoupon(couponCode)
        hideCoupons()
    }

    const inputChange = (e) => {
        let i = e.target.value
        setCoupon(i)
    }

    const removeCoupon = () => {
        let url = process.env.REACT_APP_BACKEND_URL + 'coupon/remove?userId=' + localStorage.getItem("zeluser") + '&couponCode=' + coupon + '&cartId=' + props.cartId
        let headers = { "Access-Control-Allow-Origin": "*" }
        Axios.get(url, headers)
            .then(res => {
                console.log(res)
                setApcoupon(false)
                setWrongCoupon(false)
                hideCoupons()
                setCoupon("")
                props.getUserCart()
            }).catch(err => {
                console.log(err)
            })
    }

    const goBack = () => {
        //     sessionStorage.setItem("change-vendor", true)
        history.push("/location")
    }

    const changeVendor = () => {
        sessionStorage.setItem("change-vendor", true)
        history.push("/vendor")
    }

    return (<div className="container">
        <div className="col-md-8 offset-md-2 col-sm-12 arrow">
            <span style={{
                height: 40,
                width: 40,
                padding: 6,
                backgroundColor: "#1d1d1d", color: "#fff",
                borderRadius: "50%",
                display: "inline-block"
            }}
                onClick={goBack}><ArrowBackIcon /></span>
        </div>
        <div className="col-md-8 offset-md-2">
            <Paper elevation={3}>
                <div className="main-outer">
                    <div className="upper">
                        <br />
                        <div className="col-md-5 offset-md-7">
                            <a href="tel:+918095450411" style={{ textDecoration: "none", color: "#000", float: "right" }}><PhoneIcon />&nbsp;Contact Support &nbsp; &nbsp;</a>
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: "3%", paddingBottom: "3%", paddingLeft: "3%" }}>
                        <div className="col-md-2">
                            <img src={JSON.parse(localStorage.getItem("vendor")).imgUrl} style={{ width: 100, paddingTop: "8%" }} alt="rec" />
                        </div>
                        <div className="col-md-6 recName" >
                            {"Your items will delivered by " + JSON.parse(localStorage.getItem("vendor")).vendorName}
                        </div>
                        <div className="col-md-3">
                            <span className="change menu_links" style={{ color: "#ff7907", textDecoration: "none", paddingTop: "8%" }} onClick={changeVendor}>Change</span>
                        </div>
                        <br />
                    </div>
                    <CartHeader servingsUpdate={servingsUpdate.bind(this)} />
                    <div className="row" style={{ marginBottom: 20 }}>
                        <div className="col-md-6">
                            <div className="header-ing">
                                <img src={grocery} alt="br" />
                        &nbsp; &nbsp;
                        <span style={{ fontSize: 16, fontWeight: "bold", }}>
                                    Ingredients
                        </span>
                            </div>
                        </div>
                        <div className="col-md-6" style={{ paddingRight: "5%" }} >
                            <span className="add-more-1 menu_links" style={{ color: "#ff7907", textDecoration: "none" }} onClick={searchIng}>
                                + Add more ingredients
                        </span>
                        </div>
                    </div>
                    {props.cartList.map((item, index) => {
                        return <CartMember key={index} item={item} index={index} occ={item.occurence} getCart={getCart.bind(this)} />
                    })}
                    <div style={{ borderRadius: "16px", backgroundColor: "#F0F0F0", margin: 10 }}>
                        <div className="row" style={{ marginBottom: 20 }}>
                            <div className="col-md-6">
                                <div className="header-ing2">
                                    <img src={grocerye} alt="br" />
                        &nbsp; &nbsp;
                        <span style={{ fontSize: 16, fontWeight: "bold", }}>
                                        Items not available
                        </span>
                                </div>
                            </div>
                        </div>

                        {props.unavailableList.map((item, index) => {
                            return <UnavailableMember item={item} index={index} />
                        })}

                    </div>
                    <br />
                    <Dialog onClose={hideCoupons} open={open}>
                        <DialogTitle>Available Coupons</DialogTitle>
                        <List>
                            {props.coupons.map((item, index) => {
                                return <ListItem key={index}>

                                    <button className="btn btn-link" style={{ color: "#ff7907" }} onClick={() => { setCouponValue(item.couponCode) }}>{item.couponCode}<br /></button>

                                    {/* <span>{item.desciption}</span>  */}
                                </ListItem>
                            })}
                        </List>
                    </Dialog>
                    <div className="row" style={{ paddingLeft: "3%" }}>
                        <div className="col-lg-4" >
                            <TextField
                                value={coupon}
                                defaultValue={props.coupon}
                                type="text"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={couponimg} alt="coupon" style={{ padding: 2 }} /> &nbsp;
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <img src={cross} className="menu_links" style={{ paddingRight: 5 }} alt="delete" onClick={removeCoupon} />
                                        </InputAdornment>
                                    ),
                                    disableUnderline: true,
                                }}
                                onChange={inputChange}
                                style={{ border: "none", backgroundColor: "#F3F3F3", padding: 4 }}
                                placeholder="Coupon Code"
                            /><br />
                            {apcoupon && <span className="text-success" >Coupon Applied Successfully</span>}
                            {wrongCoupon && <span className="text-danger">{wrongCouponMess}</span>}
                            {/* {props.coupons && (props.coupons.length > 0
                                && <p><button className="btn btn-link" style={{ color: "#ff7907", padding: 5, fontSize: "0.85rem" }} onClick={showCoupons}>View Available Coupons</button></p>)} */}
                        </div>
                        <div className="col-lg-3">
                            <button className="btn btn-success button-apply" onClick={applyCoupon}>Apply</button>
                            <br />
                        </div>
                        <div className="col-lg-5" style={{ paddingRight: "5%" }}>
                            <div>
                                <p style={{ fontSize: 20, float: "right", fontWeight: "600" }}>Total Amount : {props.total}
                                    <br />
                                    <span style={{ fontSize: 14, float: "right", fontWeight: 300 }}>
                                        Delivery Charges : <span style={{ color: "#ff7907" }}>{props.deliveryFee}</span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ marginTop: "5%", marginBottom: "5%" }}>
                        <div className="col-md-12" style={{ float: "right", justifyContent: "right", justifyItems: "right" }}>
                            <button type="button" className="btn btn-success btn-rounded" style=
                                {{ borderRadius: 30, padding: 10, width: 170, marginRight: 10, float: "right" }} onClick={makePayment}>
                                Proceed
                            </button>
                        </div>
                    </div>
                </div>
                <Footer />
            </Paper>
        </div>
    </div>)
}
