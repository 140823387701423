import React from 'react';
import './Location.css'
import Footer from '../Footer/footer';
import { Paper, TextField } from '@material-ui/core';
import SearchLocationInput from '../SearchInput1/SearchInput';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { GpsFixed, House, Business, Drafts } from '@material-ui/icons';
import amplitude from 'amplitude-js'

export default function FullLocation() {

    let history = useHistory();

    const [houseno, setHouseno] = React.useState('')
    const [buildingNo, setBuildingNo] = React.useState('')
    const [next, setNext] = React.useState(true)
    const [typev, setTypev] = React.useState('HOME')
    const [showOther, setShowOther] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [emailError, setEmailError] = React.useState('')

    var center = {
        lat: localStorage.getItem("lat") ? parseFloat(localStorage.getItem("lat")) : 0.0,
        lng: localStorage.getItem("long") ? parseFloat(localStorage.getItem("long")) : 0.0
    };
    var zoom = 11

    const nextScreen = () => {

        var locationJsonString = JSON.stringify({
            addressContainer: {
                address: localStorage.getItem("address"),
                buildingName: buildingNo,
                city: localStorage.getItem("city"),
                houseNo: houseno,
                instructions: "s",
                lat: localStorage.getItem("lat"),
                longtd: localStorage.getItem("long"),

            },
            checkoutPhone: localStorage.getItem("phone"),
            checkoutEmail: localStorage.getItem("email"),
            userId: localStorage.getItem("zeluser"),
            type: typev
        });

        amplitude.logEvent('Location_Proceed', {
            "Recipe_Url": localStorage.getItem("recUrl"),
            "Recipe_Name": localStorage.getItem("recName"),
            "Domain": localStorage.getItem("domain"),
            "city": localStorage.getItem("city")
        })

        axios.post(process.env.REACT_APP_BACKEND_URL + 'user/updateLocation/', locationJsonString, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        }).then(res => {
            console.log(res)
            localStorage.setItem("address", houseno + ", " + buildingNo + ", " + localStorage.getItem("city") + ", " + localStorage.getItem("address"))
            if (localStorage.getItem("newuser") == "true" && localStorage.getItem("vendor_id")!==null) {
                history.push('/slot')
            } else {
                history.push('/vendor')
                localStorage.setItem("newuser", false)
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const textChange = (event) => {
        if (event.target.name === "email") {
            let e = event.target.value
            setEmail(e)
            if(validateEmail(e)){
                setNext(false)
                setEmailError("")
            }else{
                setEmailError("Invalid Email")
            }
        }

        if (event.target.name === "apartment") {
            setBuildingNo(event.target.value)
        }

        if (event.target.name === "house") {
            setHouseno(event.target.value)
        }

        if(event.target.name === "others"){
            setTypev(event.target.value)
        }

    }

    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const homeClick = (e) => {
        e.target.style.backgroundColor = '#ff7907'
        document.getElementById("other").style.backgroundColor = '#6c757d'
        document.getElementById("office").style.backgroundColor = '#6c757d'
        setTypev('HOME')
    }

    const officeClick = (e) => {
        e.target.style.backgroundColor = '#ff7907'
        document.getElementById("other").style.backgroundColor = '#6c757d'
        document.getElementById("home").style.backgroundColor = '#6c757d'
        setTypev('OFFICE')
    }

    const otherClick = (e) => {
        e.target.style.backgroundColor = '#ff7907'
        document.getElementById("office").style.backgroundColor = '#6c757d'
        document.getElementById("home").style.backgroundColor = '#6c757d'
        setShowOther(true)
        setTypev('Others')
    }

    const refreshPage = () => {
        if (localStorage.getItem("newuser") == "true" && localStorage.getItem("vendor_id")!==null) {
            history.push('/cart')
        } else {
            window.location.reload();
        }
    }


    return (<div className="container">
        <div className="col-md-8 offset-md-2 col-sm-12 arrow">
            <span style={{
                height: 40,
                width: 40,
                padding: 6,
                backgroundColor: "#1d1d1d", color: "#fff",
                borderRadius: "50%",
                display: "inline-block"
            }}
                onClick={refreshPage}><ArrowBackIcon /></span>
        </div>
        <div className="col-md-8 offset-md-2">
            <Paper elevation={3}>
                <div className="outer col-md-10 offset-md-1">
                    <h1 className="title-add">Add New Address</h1>
                    <br />
                    {/* <div style={{ height: '200px', width: '100%' }}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyC1LwVy7NSTPfEPdVVvBkXGwF2PWp7odJI" }}
                            defaultCenter={center}
                            defaultZoom={zoom}>
                            <RoomIcon
                                lat={center.lat}
                                lng={center.lng}
                                text="My Marker"
                            />
                        </GoogleMapReact>
                    </div> */}

                    {/* <br /> */}
                    <GpsFixed className="gps-icon" /> &nbsp; &nbsp;Location<span className="text-danger">*</span><span className="gps-text"></span>
                    <SearchLocationInput />
                    <br />
                    <House className="gps-icon" /> &nbsp; &nbsp;House / Flat no.<span className="text-danger">*</span> <span className="gps-text"></span>
                    <div className="input-group">
                        &nbsp;  &nbsp;&nbsp; &nbsp;<input className="form-control in-loca"
                            onChange={textChange}
                            placeholder="e.g, 2011"
                            value={houseno}
                            name="house"
                            required
                        />
                        <hr style={{ width: "100%", height: 2 }} />
                    </div>
                    <br />
                    <Business className="gps-icon" /> &nbsp; &nbsp;Building / Apartment name<span className="text-danger">*</span> <span className="gps-text"></span>
                    <div className="input-group">
                        &nbsp;  &nbsp;&nbsp; &nbsp;<input className="form-control in-loca"
                            onChange={textChange}
                            placeholder="e.g, Prestige Ferns Residency"
                            value={buildingNo}
                            required
                            name="apartment"
                        />
                        <hr style={{ width: "100%", height: 2 }} />
                    </div>
                    <br />
                    <Drafts className="gps-icon" /> &nbsp; &nbsp;Email Address<span className="text-danger">*</span> <span className="gps-text"></span>
                    <div className="input-group">
                        &nbsp;  &nbsp;&nbsp; &nbsp;
                        <input className="form-control in-loca"
                            onChange={textChange}
                            placeholder="e.g, jithuvarghese@gmail.com"
                            value={email}
                            type="email"
                            required
                            name="email"
                        />
                        
               
                     
                    </div>
                    <span className="text-danger">{emailError}</span>
                    <hr style={{ width: "100%", height: 2 }} />
                    <br />
                    Save Address as<br />
                    <br />

                    <button id="home" type="button" className="btn btn-secondary" onClick={homeClick}>Home</button>
                    &nbsp; &nbsp; &nbsp;
                    <button id="office" type="button" className="btn btn-secondary" onClick={officeClick}>Office</button>
                    &nbsp; &nbsp; &nbsp;
                    <button id="other" type="button" className="btn btn-secondary" onClick={otherClick}>Other</button>
                    &nbsp; &nbsp; &nbsp;
                    {showOther && <TextField name="others" onChange={textChange}/>}
                    <br />
                    <br />

                    <div className="col-md-3 offset-md-9">
                        <button className="btn btn-success btn-rounded" disabled={next} onClick={nextScreen}>Continue</button>
                    </div>

                </div>

                <Footer />
            </Paper>
        </div>
    </div>)
}