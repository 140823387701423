import React, { Fragment } from 'react';
import CartScreen from '../components/Cart/CartScreen';
import axios from 'axios';
import AddMoreIngredients from '../components/Cart/AddMoreIngredients';
import { LinearProgress } from '@material-ui/core';

class CartContainer extends React.Component {

  state = {
    cartList: [],
    vendor: JSON.parse(localStorage.getItem("vendor")),
    total: 0,
    search: false,
    loading: false,
    deliveryFee: 0,
    coupons: [],
    cartId: '',
    coupon: '',
    unavailableList:[]
  }


  servingsUpdate = (ser) => {
    this.startLoading()
    console.log(ser)
    let url = process.env.REACT_APP_BACKEND_URL + 'shopnow/servings/update?userId=' + localStorage.getItem("zeluser") + '&originalServings=' + parseInt(localStorage.getItem("servings")) + '&newServings=' + ser + '&vendorId=' + this.state.vendor.vendorId;
    console.log(url)
    console.log(this.state.cartList)

    axios.get(url, {
      headers: {
        "Access-Control-Allow-Origin": "*"
      }
    }).then(res => {
      console.log(res)
      this.setState({ cartList: res.data.data.cartItemsList, total: res.data.data.grandTotal })
      this.stopLoading()
      //   this.getUserCart()
    }).catch(err => {
      console.error(err)
    })
  }

  handleToUpdate(total) {
    let t = total;
    this.setState({ total: t })
  }

  getCoupons = () => {
    let url = process.env.REACT_APP_BACKEND_URL + 'coupon/?userId=' + localStorage.getItem("zeluser") + '&vendorId=' + this.state.vendor.vendorId
    var headers = { "Access-Control-Allow-Origin": "*" }
    axios.get(url, headers)
      .then(res => {
        this.setState({ coupons: res.data.data })
        console.log(this.state.coupons)
      }).catch(err => {
        console.log(err)
      })
  }

  searchTrue() {
    this.setState({ search: true })
  }

  searchFalse() {
    this.setState({ search: false })
  }

  startLoading = () => {
    this.setState({ loading: true })
  }

  stopLoading = () => {
    this.setState({ loading: false })
  }

  removeCoupon = () => {
    let url = process.env.REACT_APP_BACKEND_URL + 'coupon/remove?userId=' + localStorage.getItem("zeluser") + '&couponCode=' + this.state.coupon + '&cartId=' + this.state.cartId
    let headers = { "Access-Control-Allow-Origin": "*" }
    axios.get(url, headers)
      .then(res => {
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
  }

  getUserCart = () => {
    console.log(this.state.vendor.vendorId)
    localStorage.setItem("vendor_id", this.state.vendor.vendorId)
    var myJsonString = JSON.stringify({
      userId: localStorage.getItem("zeluser"),
      vendorId: this.state.vendor.vendorId
    });

    axios.post(process.env.REACT_APP_BACKEND_URL + 'cart/getUserCartEssential/v1', myJsonString, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
      }
    }).then(res => {
      console.log(res.data.data)
      var list = res.data.data.cartItemsList;
      list.forEach(item => {
        item.itemName = item.name
      })
      this.setState({
        cartList: list, 
        total: res.data.data.grandTotal, 
        deliveryFee: res.data.data.deliveryFee, 
        cartId: res.data.data.cartId,
        coupon: res.data.data.couponApplied,
        unavailableList: res.data.data.unavailableItemList

      });
      localStorage.setItem("cpn", res.data.data.couponApplied)
      console.log(this.state.coupon)
      this.stopLoading()
    }).catch(function (error) {
      console.log(error);
    })
  }

  componentDidMount() {
    document.body.style.fontFamily = "SFProDisplay"
    this.startLoading()
    if (localStorage.getItem("guest") === "true") {
      this.setState({cartList : JSON.parse(localStorage.getItem("vendor-cart")), total : localStorage.getItem("vendor-total")})
      this.stopLoading()
    }else{
      this.getUserCart()
      this.removeCoupon()
      this.getCoupons()
    }
    
  }

  render() {
    return (<Fragment>
      {this.state.loading && <LinearProgress />}
      {this.state.search ? <AddMoreIngredients getUserCart={this.getUserCart.bind(this)} searchFalse={this.searchFalse.bind(this)} startLoading={this.startLoading} stopLoading={this.stopLoading} />
        : <CartScreen
          cartList={this.state.cartList}
          total={this.state.total}
          coupons={this.state.coupons}
          deliveryFee={this.state.deliveryFee}
          servingsUpdate={this.servingsUpdate.bind(this)}
          searchTrue={this.searchTrue.bind(this)}
          getUserCart={this.getUserCart.bind(this)}
          cartId={this.state.cartId}
          coupon={this.state.coupon}
          unavailableList={this.state.unavailableList}
        />}

    </Fragment>);
  }
}

export default CartContainer;