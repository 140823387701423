import { Box, Paper, Typography, RadioGroup, Radio, FormControlLabel, Tabs, Tab } from '@material-ui/core';
import React from 'react';
import './Vendor.css'
import { useHistory } from "react-router-dom";
import Footer from '../Footer/footer';
import RoomIcon from '@material-ui/icons/Room';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export default function SlotSelect(props) {

    let history = useHistory();

    const vendor = props.vendor;

    var dtdt = [];

    const [value, setValue] = React.useState(0);

    const [selectedValue, setSelectedValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    };

    const handleSlotChange = (event, p) => {
        setSelectedValue(parseInt(event.target.value));
    };

    const updateLocation = () => {
        history.push('/location')
    }

    const updateVendor = () => {
        sessionStorage.setItem("change-vendor", true)
        history.push('/vendor')
    }

    const selectSlot = () => {
        console.log(vendor)

        localStorage.setItem("date", dtdt[value])
        localStorage.setItem("slotIndex", vendor.deliveryDates[value].slots[selectedValue].slotNo)

        console.log(localStorage.getItem("newuser"))
        history.push('/payment')

        
        
    }

    return (<div className="container">
         <div className="col-md-8 offset-md-2 col-sm-12 arrow">
            <span style={{
                height: 40,
                width: 40,
                padding: 6,
                backgroundColor: "#1d1d1d", color: "#fff",
                borderRadius: "50%",
                display: "inline-block"
            }}
                onClick={() => { history.push("/cart") }}><ArrowBackIcon /></span>
        </div>
        <div className="col-md-8 offset-md-2">
            <Paper elevation={3}>
                <div className="outer col-md-10 offset-md-1">
                    <h1 className="title">Delivery Date &amp; Time</h1>
                    <br />
                    <div className="row">
                        <div className="col-md-10">
                            <span className="address"><RoomIcon /> &nbsp; {localStorage.getItem("address")}</span>
                        </div>

                        <div className="col-md-1">
                            <span className="change menu_links" style={{color:"#ff7907", textDecoration:"none"}} onClick={updateLocation}>Change</span>
                        </div>
                    </div>
                    <hr className="line" />
                    <div className="row">
                        <div className="col-md-10">
                            <span className="address"><RoomIcon /> &nbsp;{" Deliver by " + vendor.vendorName}</span>
                        </div>

                        <div className="col-md-1">
                            <span className="change menu_links" style={{color:"#ff7907", textDecoration:"none"}} onClick={updateVendor}>Change</span>
                        </div>
                    </div>
                    <hr className="line" />
                    <br />
                        <div className="row" style={{ marginTop: "5%", borderRadius: "8px", border: "solid 0.5px #cacccf"}}>
                            <Paper>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    centered>
                                    {vendor.deliveryDates.length > 0 ?
                                        vendor.deliveryDates.map((date, index) => {
                                            dtdt.push(date.dateDel)
                                            return <Tab label={date.dateDel} />

                                        }) : "No SLots"}
                                </Tabs>

                            </Paper>
                                {vendor.deliveryDates.length > 0 ?
                                    vendor.deliveryDates.map((d, index) => {
                                        return <TabPanel value={value} index={index}>
                                            <RadioGroup aria-label="slot" name="slot1" value={selectedValue}
                                                onChange={(e) => { handleSlotChange(e, d) }}>
                                                {(d.slots.length) > 0 ?
                                                    d.slots.map((p, i) => {
                                                        return (
                                                            <FormControlLabel value={i} control={<Radio />}
                                                                label={p.fromTime + " - " + p.toTime} />)
                                                    }) : null}
                                            </RadioGroup>
                                        </TabPanel>

                                    }) : "No SLots"}

                        </div>

                        <div className="row">
                            <div className="col-md-3 offset-md-7">
                                <button type="button" className="btn btn-success btn-rounded"
                                    style={{
                                        borderRadius: 30,
                                        padding: 10,
                                        width: 130,
                                        marginTop: "10%",
                                        marginLeft: "50%",
                                    }}
                                    onClick={selectSlot}>
                                    Continue
                </button>
                            </div>
                        </div>

                </div>
                <Footer />
            </Paper>
        </div>
    </div>)
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
