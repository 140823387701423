import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import './Home.css'
import { useHistory } from "react-router-dom";
import Footer from '../Footer/footer';
import phoneIc from '../../images/phone-icon.png'

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Axios from 'axios';
import amplitude from "amplitude-js";

export default function Home() {

    let history = useHistory();

    const handleLoginClick = () => {
        amplitude.logEvent('Start_Phone', {
            "Recipe_Url": localStorage.getItem("recUrl"),
            "Recipe_Name": localStorage.getItem("recName"),
            "Domain": localStorage.getItem("domain")
        })

        if (localStorage.getItem("zeluser") != null) {
            addCart()
            history.push("/location");
        } else {
            history.push("/login");
        }
    }

    const handleGuestClick = () => {
        localStorage.setItem("guest", true)
        amplitude.logEvent('Start_Phone', {
            "Recipe_Url": localStorage.getItem("recUrl"),
            "Recipe_Name": localStorage.getItem("recName"),
            "Domain": localStorage.getItem("domain")
        })
        history.push("/location");

    }

    const addCart = () => {
        var items = JSON.parse(localStorage.getItem("cart")).shopNowResponses
        console.log(items)
        items.forEach((item) => {
            item.name = item.itemName
            item.quantityValue = item.quantity
        })
        var jsonString = JSON.stringify(items);

        items.length > 0 &&
            Axios.post(process.env.REACT_APP_BACKEND_URL + 'shopnow/cart/add?userId=' + localStorage.getItem("zeluser"), jsonString, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(res => {
                console.log(res.data);
                history.push('/location')
            }).catch(error => {
                alert("Couldn't add cart")
                console.log(error);
            })
    }

    return (<div className="container">
        <div className="col-md-8 offset-md-2 col-sm-12 arrow">
            <span style={{
                height: 40,
                width: 40,
                padding: 6,
                backgroundColor: "#1d1d1d", color: "#fff",
                borderRadius: "50%",
                display: "inline-block"
            }}
                onClick={() => { history.push("/") }}><ArrowBackIcon /></span>
        </div>
        <div className="col-md-8 offset-md-2">
            <Paper elevation={3}>
                <div className="main">
                    <Typography className="typo">
                        <p className="text1 text2">Fresh Approach To Shopping</p>
                        <p className="text1"  style={{marginTop:"-1%"}}>Now shop ingredients from recipes directly!</p>
                    </Typography>
                    <div align="center" className="buttons">
                        <button className="phone" onClick={handleLoginClick}><img src={phoneIc}/> &nbsp; Login with Phone</button>
                        <br />
                        {/* <center>or</center>
                        <br />
                        <button className="guest" onClick={handleGuestClick}>Continue as Guest</button> */}
                    </div>
                    <br/>
                    <div className="row" style={{textAlign:"center", color:"#063021"}}>
                        <div className="col-md-10 offset-md-1 text-home">
                        70,000+ happy customers! <br/>

We now deliver across Bangalore, Mumbai, Delhi, Pune &amp; Kolkata. <br/>
Coming soon in other cities..
                        </div>
                   
                    </div>
                </div>
                <Footer />
            </Paper>
        </div>
    </div>)
}
