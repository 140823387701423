import Axios from 'axios';
import React, { useState } from 'react';
import ico_minus from '../../images/ico-minus.png'
import ico_plus from '../../images/ico-plus.png'


export default function CartMember({ item, index, getCart }) {

  const updateCartItem = (i) => {
    delete item.cartItemType;
    item.occurence = i;
    var jsonString = JSON.stringify({
      cartItemsList: [item],
      userId: localStorage.getItem("zeluser")
    })
    Axios.post(process.env.REACT_APP_BACKEND_URL + 'cart/updateCartItem/v1', jsonString, {
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*"
      }
    }).then(res => {
      console.log(res)
      getCart()
    }).catch(err => {
      console.log(err)
    })
  }

  const handlePlusClick = () => {
    if (item.occurence > 0) {
      var i = item.occurence + 1;
      updateCartItem(i)
    }

  }

  const handleMinusClick = () => {
    if (item.occurence - 1 > 0) {
      var i = item.occurence - 1
      updateCartItem(i)
    } else {
      var i = item.occurence - 1
      updateCartItem(i)
    }

  }


  return (
    <div className="row" >
      <div className="col-6" style={{ paddingLeft: "5%", marginBottom:5 }}>
        <span className="item-name">{(index + 1) + ".   " + item.name}</span><br />
        <span>&nbsp; &nbsp;</span>
        <span className="gms" style={{ color: "#696969", float: "left", fontSize: 13, paddingLeft: "3.5%" }}>
          {"  " + item.quantityValue + " " + item.quantityUnit}
        </span><br/>
        <span className="item-price ipm">{"₹" + item.price}</span>

      </div>
      <div className="col-6" style={{ paddingRight: "5%" }}>
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <img src={ico_plus} className="ico_minus" onClick={handlePlusClick} alt="" style={{ float: "right" }} />
            <span className="item-occ" style={{ marginLeft: 10, marginRight: 10, float: "right" }}>{item.occurence}</span>
            <img src={ico_minus} className="ico_minus" onClick={handleMinusClick} style={{ float: "right" }} alt="" />
          </div>
          <div className="col-md-3">
          <span className="item-price ipp">{"₹" + item.price}</span>
          </div>

        </div>

      </div>
      <hr style={{ width: "90%", height: 2, marginTop: "0"}} />
    </div>
  );
}
