import { LinearProgress } from '@material-ui/core';
import React, { Fragment } from 'react';
import Login from '../components/Login/Login';
import Otp from '../components/Login/Otp';

class LoginContainer extends React.Component {

  state = {
    show : true,
    loading : false
  }

  startLoading = () => {
    this.setState({loading:true})
  }

  stopLoading = () => {
    this.setState({loading:false})
  }

  showOtp = () =>{
    this.setState({show:false})
  }

  showMobile = () =>{
    this.setState({show:true})
  }
  
  componentDidMount(){
  
    document.body.style.fontFamily = "SFProDisplay"
    }

    render() {
      return (<Fragment>
        {this.state.loading && <LinearProgress/>}
        {this.state.show?  <Login showOtp = {this.showOtp} startLoading = {this.startLoading} stopLoading = {this.stopLoading}/> : <Otp showMobile = {this.showMobile} startLoading = {this.startLoading} stopLoading = {this.stopLoading}/>}
     {/* <Otp/> */}
      </Fragment>);
    }
  }

export default LoginContainer;