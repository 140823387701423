import { Paper, withWidth } from '@material-ui/core';
import React from 'react';
import { useHistory } from "react-router-dom";
import Footer from '../Footer/footer';
import orderCancel from '../../images/order-cancel.png'
import site_logo from '../../images/site_logo.png'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';


export default function NoIngridients() {

    const goHome = () => {
        history.push('/')
    }

    let history = useHistory();
    return (<div className="container">
        <div className="col-md-8 offset-md-2" style={{marginTop:"5%"}}>
            <Paper elevation={3}>
                <div className="outer">
                    <div class="row">
                        <div class="col-5 col-lg-9"></div>
                        <div class="col-7 col-lg-3">
                            <img src={site_logo} style={{width:"121px", marginTop:"46px"}} ></img>
                        </div>
                    </div>
                    <div class="row" style={{ paddingTop: 50, paddingLeft: "10%" }}>
                        <span style={{ float: "left" }}>
                            <img class="manImg" src={orderCancel} style={{ maxWidth: 300 }}></img>
                        </span>
                    </div>
                    <div class="row">
                        <div class="col-md-11">
                            <h1 style={{ letterSpacing: 2, textStyle: "bold", fontWeight: "bolder", marginLeft: "5%", marginTop: 30 }}>
                                Can't Load Ingredients</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-11" style={{ marginLeft: "5%" }}>
                            <p style={{ letterSpacing: 1, marginTop: 10 }}>
                                We couldn't identify any ingredients from your recipe. Please bear with us while we sort out this issue. For further support we are available at				</p>
                            <a href="mailto:talkto@tinychef.ai" className="phone-contact" style={{ textDecoration: "none", color: "#FF7900" }}>talkto@tinychef.ai</a>
                        </div>
                    </div>
                   <br/>  <br/>
                </div>
                <Footer />
            </Paper>
        </div>
    </div>)
}
