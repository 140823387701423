import React, { Fragment } from 'react';
import Home from '../components/Home/Home';
import axios from 'axios';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LinearProgress } from '@material-ui/core';
import amplitude from "amplitude-js";
import NoIngridients from '../components/Home/NoIngridients';

class HomeContainer extends React.Component {

  state = {
    error: false,
    loading: false
  }

  handleButton(urll) {

    localStorage.setItem("recUrl", urll)
    let url = process.env.REACT_APP_BACKEND_URL + 'shopnow/?url=' + urll;
    var headers = { "Access-Control-Allow-Origin": "*" }
    axios.get(url, headers)
      .then(res => {
        console.log(res.data)
        localStorage.setItem("recName", res.data.data.recipeName);
        localStorage.setItem("imgUrl", res.data.data.imgUrl);
        localStorage.setItem("servings", res.data.data.servings)
        amplitude.logEvent('Any_Contact', { 'user': this.state.isLoggedIn ? localStorage.getItem("zeluser") : "empty", "Recipe_Url": localStorage.getItem("recUrl"), "Recipe_Name": localStorage.getItem("recName") });
        localStorage.setItem("cart", JSON.stringify(res.data.data));
        if(res.data.data.shopNowResponses.length===0){
          this.setState({ error: true, loading: false })
        }
        this.setState({ loading: false })
      }).catch(err => {
        console.log(err)
        this.setState({ error: true, loading: false })
      })
  }


  componentDidMount() {
    localStorage.clear();
    this.setState({ loading: true })
    const query = new URLSearchParams(this.props.location.search);
    const urll = query.get('url')
    console.log("URL passed : " + urll);

    if (urll && urll.length > 0) {
      this.handleButton(urll);
      try {
        let arr = urll.split("/");
        let result = arr[0] + "//" + arr[2]
        localStorage.setItem("domain", result)
      }
      catch (err) {
        console.log("Can't get Domain")
      }
    }else{
      this.setState({ error: true, loading: false })
    }
    }

    render() {

      if(this.state.error)
        return <NoIngridients/>

      return (<Fragment>
        {this.state.loading && <LinearProgress />}
        
        <Home />
      </Fragment>);
    }
  }

  export default HomeContainer;