import React from 'react';
import './Location.css'
import Footer from '../Footer/footer';
import { Paper } from '@material-ui/core';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import SearchLocationInput from '../SearchInput/SearchInput';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import amplitude from 'amplitude-js'

export default function LocationScreen1() {

    let history = useHistory();

    const proceedButtonClick = () => {

        var location = {
            addressContainer: {
                address: localStorage.getItem("address"),
                buildingName: "Building Name",
                city: localStorage.getItem("city"),
                houseNo: "House No",
                instructions: "Instructions",
                lat: localStorage.getItem("lat"),
                longtd: localStorage.getItem("long"),
            },
            checkoutPhone: localStorage.getItem("phone"),
            userId: localStorage.getItem("zeluser"),
            type : "HOME"
        }

        amplitude.logEvent('Location_Proceed', {
            "Recipe_Url": localStorage.getItem("recUrl"),
            "Recipe_Name": localStorage.getItem("recName"),
            "Domain": localStorage.getItem("domain"), 
            "city": localStorage.getItem("city")
        })

        if (localStorage.getItem("guest") === "true") {
            history.push('/vendor')
        }else{
            updateLocation(location)
        }
        
    }

    const updateLocation = (location) => {
        var locationJsonString = JSON.stringify(location);
        axios.post(process.env.REACT_APP_BACKEND_URL+'user/updateLocation/', locationJsonString, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        }).then(res => {
            console.log(res)
            localStorage.setItem("newuser", true)
            history.push('/vendor')
        }).catch(err => {
            console.log(err);
        })
    }


    return (<div className="container">
         <div className="col-md-8 offset-md-2 col-sm-12 arrow">
            <span style={{
                height: 40,
                width: 40,
                padding: 6,
                backgroundColor: "#1d1d1d", color: "#fff",
                borderRadius: "50%",
                display: "inline-block"
            }}
                onClick={() => { history.push("/") }}><ArrowBackIcon /></span>
        </div>
        <div className="col-md-8 offset-md-2">
            <Paper elevation={3}>
                <div className="outer col-md-9 offset-md-1">
                    <h1 className="title">Delivery Location</h1>
                    <br />
                    <span >We need your location details to enable door step delivery</span>
                    <br />
                    <br />
                    <SearchLocationInput />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <div className="col-md-3 offset-md-9">
                        <button className="btn btn-success btn-rounded" style={{
                                                    borderRadius: 30,
                                                    padding: 8,
                                                    width: 130
                                                }}
                                                onClick={proceedButtonClick}>Continue</button>
                    </div>

                </div>

                <Footer />
            </Paper>
        </div>
    </div>)
}