import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import HomeContainer from './containers/HomeContainer'
import LoginContainer from './containers/LoginContainer'
import LocationContainer from './containers/LocationContainer';
import VendorContainer from './containers/VendorContainer';
import CartContainer from './containers/CartContainer';
import PaymentContainer from './containers/PaymentContainer';
import SlotContainer from './containers/SlotContainer';
import Thankyou from './containers/ThankyouContainer';
import amplitude from "amplitude-js";
import FullLocation from './components/Location/DetailLocationScreen';
import PaymentFail from './containers/PaymentFailContainer';


function App() {
  return (
    <BrowserRouter>
      {amplitude.getInstance().init('a242679499dce36caebc2b8e338c16d6')}
      <Switch>
        <Route exact path="/" component = {HomeContainer}/>
        <Route exact path="/login" component = {LoginContainer}/>
        <Route exact path="/location" component = {LocationContainer}/>
        <Route exact path="/vendor" component = {VendorContainer}/>
        <Route exact path="/cart" component = {CartContainer}/>
        <Route exact path="/payment" component = {PaymentContainer}/>
        <Route exact path="/slot" component = {SlotContainer}/>
        <Route exact path="/thankyou" component = {Thankyou}/>
        <Route exact path="/register" component = {FullLocation}/>
        <Route exact path="/failed" component = {PaymentFail}/>

      </Switch>

    </BrowserRouter>
  );
}

export default App;
