import React from 'react';
import { Component, Fragment } from "react";
import logo from '../images/site_logo.png'
import orderCancel from '../images/order-cancel.png'
import { Redirect } from 'react-router-dom';
import { Paper } from '@material-ui/core';


class PaymentFail extends Component {

	state = {
		redirect: false,
	}

	componentDidMount() {
		document.body.style.fontFamily = "SFProDisplay"
	}

	render() {
		return (
			<Fragment>
				{ this.state.redirect && <Redirect to={{
					pathname: '/',

				}} />}
				<div className="container" style={{ marginTop: "3%" }}>
					<div className="col-md-8 offset-md-2">
						<Paper elevation={3}>
							<div className="container">
								<div class="row" style={{ paddingTop: 50, paddingLeft: "10%" }}>
									<span style={{ float: "left" }}>
										<img class="manImg" src={orderCancel} style={{ maxWidth: 300 }}></img>
									</span>
								</div>
								<div class="row">
									<div class="col-md-11">
										<h1 style={{ letterSpacing: 2, textStyle: "bold", fontWeight: "bolder", marginLeft: "5%", marginTop: 30 }}>
                                        Order Cancelled</h1>
									</div>
								</div>
								<div class="row">
									<div class="col-md-11" style={{marginLeft: "5%"}}>
										<p style={{ letterSpacing: 1, marginTop: 10 }}>
                                        Sorry, your order couldn't be processed at this time. Please contact our support at 
				</p>
                <a href="mailto:talkto@tinychef.ai" className="phone-contact" style={{textDecoration:"none", color:"#ff7907"}}>talkto@tinychef.ai</a>
									</div>
								</div>
								<br/><br/>
							</div>

							<div>
								<hr style={{ width: "100%", height: 2 }} />
								<p style={{ paddingLeft: "3%" }}>
									Powered By &nbsp; &nbsp;
					  <img src={logo} style={{ height: 18 }} />
									<a style={{ float: "right", paddingRight: "3%", fontSize: 14 }}>
										About Tinychef  |  Terms of service
					  </a>
								</p>
							</div>
							<div className="grad1"></div>

						</Paper>
					</div>

				</div>
			</Fragment>


		);
	}
}

export default PaymentFail;

