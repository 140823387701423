import { Paper } from '@material-ui/core';
import React from 'react';
import './CartScreen.css'
import Footer from '../Footer/footer';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import axios from 'axios';

export default function AddMoreIngredients(props) {


    const [searchKey, setSearchKey] = React.useState('')
    const [searchResult, setSearchResult] = React.useState([])


    const handleTextChange = (e) =>{
        var searchVal = e.target.value;
        setSearchKey(searchVal)
    }

    const handleKeyPress = (e) => {
        console.log(e.keyCode)
        if (e.keyCode === 13){
            props.startLoading()
            var jsonString = JSON.stringify({ searchString: searchKey })

            axios.post(process.env.REACT_APP_BACKEND_URL+'cart/searchVendorProducts/'+localStorage.getItem("vendor_id"), jsonString, {
                headers: {
                    'Content-Type': 'application/json',
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(res => {
                var arr = res.data.data.cartItemsList;
                setSearchResult(arr)
                console.log(arr)
                props.stopLoading()
            }).catch(error => {
                console.log(error);
            })
        }
        
    }

    const searchStop = () => {
        props.getUserCart()
        props.searchFalse()
        
    }

    const addCartItems = (item) => {
        props.startLoading()
        var jsonStr = JSON.stringify({
            quantityList : [{
                image : item.image,
                itemId : item.itemId,
                name : item.name,
                occurence : 0,
                price : item.price,
                quantityUnit : item.quantityUnit,
                quantityValue : item.quantityValue,
                unitItemPrice : item.unitItemPrice,
            }],
            userId : localStorage.getItem("zeluser")
        });

        axios.post(process.env.REACT_APP_BACKEND_URL+'cart/addCartItems',jsonStr, {
            headers: {
                'Content-Type': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        }).then(res=>{
            console.log(res)
            alert("Added "+item.name)
             props.stopLoading()
            // props.getUserCart()
            // props.searchFalse()
           
        }).catch(err =>{
            console.log(err)
            alert("Error occured in API")
            props.stopLoading()
            // props.getUserCart()
            // props.searchFalse()
            
        })

    }

    return (<div className="container">
        <div className="col-md-8 offset-md-2 col-sm-12 arrow">
            <span style={{
                height: 40,
                width: 40,
                padding: 6,
                backgroundColor: "#1d1d1d", color: "#fff",
                borderRadius: "50%",
                display: "inline-block"
            }}
                onClick={searchStop}><ArrowBackIcon /></span>
        </div>
        <div className="col-md-8 offset-md-2">
            <Paper elevation={3}>
                <div className="main-outer">
                    <br />
                    <br />
                    <div className="row">
                        <div className="col-md-7 offset-md-1">
                            <div className="input-group">
                                &nbsp;  &nbsp;&nbsp; &nbsp;
                                <input className="form-control in-loca"
                                onChange={handleTextChange}
                                onKeyDown={handleKeyPress}
                                placeholder="Search for an item"
                                value={searchKey}
                                required
                                />

                            </div>
                        </div>

                        <div className="col-md-3">
                            <button type="button" className="btn btn-success btn-rounded" style=
                                {{ borderRadius: 30, padding: 10, width: 170, float: "right" }} onClick={searchStop}>
                                View Cart
                </button>
                        </div>

                    </div>

                    <br/>
                    <br/>

                    <div className="row" style={{marginLeft:"10%", marginRight:"10%"}}>
                        {searchResult.length>0 && searchResult.map((item, index)=>{
                           return (<div className="col-md-4" key={index} style={{width: 200, height: 310, padding: "15px"}}>
                               <Paper>
                                   <img src={item.image} style={{height: 120}} alt= "" />
                              <span>{item.name}</span> 
                               <br/>
                               <div className="row">
                                   <div className="col-md-6">
                                       {item.quantityValue + ""+item.quantityUnit}
                                   </div>
                                   <div className="col-md-6">
                                       {"₹ "+item.price}
                                   </div>
                               </div>
                               <br/>
                               <button className="cart-add" onClick={()=>{addCartItems(item)}}>Add</button>
                               </Paper>
                           </div>)
                        })}
                    </div>

                </div>
                <Footer />
            </Paper>
        </div>
    </div>)
}
